<template>
  <!-- <v-dialog
    v-model="showForm"
    max-width="1000px"
    persistent
    transition="dialog-top-transition"
    @keydown.esc="close"
  > -->
  <v-container fluid>
    <v-row class="text-left">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="2" md="2">
        <datepicker
          label="Date from"
          :edit="true"
          v-model="date_from"
          :clearable="false"
          :key="cs"
        ></datepicker>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <datepicker
          label="Date to"
          :edit="true"
          :clearable="false"
          v-model="date_to"
          :key="cs"
        ></datepicker>
      </v-col>
      <v-col cols="12" sm="2" md="2">
        <v-btn @click.stop="refresh">
          <v-icon> mdi-refresh </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <div id="chart_element">
        <canvas :id="chart_id" style="background-color: white"></canvas>
      </div>
      <!-- <v-card-actions>
      <v-col class="text-right">
        <v-btn color="blue darken-1" @click.stop="dialog_close()" class="ma-1">
          Close
        </v-btn>
      </v-col>
    </v-card-actions> -->
    </v-card>
  </v-container>
  <!-- </v-dialog> -->
</template>

<script>
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
import Chart from "chart.js";
import TEST_QAPM from "../graphql/WellTest/APM/TEST_QAPM.gql";

export default {
  components: {
    datepicker: () => import("../components/DatePicker.vue"),
  },
  name: "formchart",
  props: {
    well_id: Number,
    tubulars_list: Array,
    chart_id: String,
  },

  data: () => ({
    data_list: [],
    date_from: null,
    date_to: null,
    cs: 100,
    first: 1,
  }),

  async created() {
    this.refresh();
  },
  computed: {
    today() {
      return this.$store.state.today;
    },
  },
  methods: {
    async refresh() {
      if (this.well_id) {
        const data = {
          labels: [],
          datasets: [],
        };
        // Custom plugin for drawing text annotations
        const textPlugin = {
          beforeDraw: function (chart) {
            const ctx = chart.chart.ctx;
            //const chartArea = chart.chartArea;
            const datasets = chart.data.datasets;

            datasets.forEach((dataset, datasetIndex) => {
              const meta = chart.getDatasetMeta(datasetIndex);
              if (meta.hidden) return;

              const x = meta.data[0]._model.x;
              const y = meta.data[0]._model.y;

              ctx.save();
              ctx.font = "12px Arial";
              ctx.fillStyle = dataset.borderColor;
              ctx.fillText(dataset.label, x + 5, y - 10);
              ctx.restore();
            });
          },
        };

        // Register the plugin
        Chart.plugins.register(textPlugin);

        const config = {
          type: "line",
          data: data,
          options: {
            backgroundColor: "white",
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
              xAxes: [
                {
                  display: true,
                },
              ],
            },
            legend: {
              display: false, // Disable the legend
            },
          },
        };
        let r = await this.$maj(TEST_QAPM, {
          WellID: this.well_id,
          date_from: this.date_from,
          date_to: this.date_to,
        });
        if (r.ok) {
          this.data_list = r.data.test_qapm;
          if (this.data_list.length > 0) {
            if (this.first == 1) {
              this.date_from = this.data_list[0].testdate;
              this.date_to = this.data_list[this.data_list.length - 1].testdate;
              this.first++;
            }
            this.cs++;
            let d = this.data_list
              .map((elm) => elm.testdate)
              .filter(onlyUnique);

            data.labels = d;
            var unique = this.data_list
              .map((elm) => elm.well_tubular_id)
              .filter(onlyUnique);
            let i = 1;
            unique.forEach((element) => {
              let l = this.data_list.filter(
                (e) => e.well_tubular_id == element
              );
              let c;
              if (i == 1) c = "#e8c3b9";
              if (i == 2) c = "#8e5ea2";
              if (i == 3) c = "#3e95cd";
              if (i == 4) c = "#008000";
              if (i == 5) c = "#FF0000";
              i++;
              data.datasets.push({
                label: l[0].label,
                data: l.map((elm) => elm.value),
                fill: false,
                backgroundColor: c,
                borderColor: c,
                borderWidth: 1,
              });

              if (l[0].tubular_id != 1)
                data.datasets.push({
                  label: l[0].label + " MAASP",
                  data: l.map((elm) => elm.actual_maasp),
                  fill: false,
                  pointRadius: 0,
                  backgroundColor: c,
                  borderColor: c,
                  borderWidth: 5,
                });
            });
          } else {
            let i = 1;

            this.tubulars_list
              .filter((elm) => elm.active == 1)
              .forEach((element) => {
                let c;

                if (i == 1) c = "#e8c3b9";
                if (i == 2) c = "#8e5ea2";
                if (i == 3) c = "#3e95cd";
                if (i == 4) c = "#008000";
                if (i == 5) c = "#FF0000";
                i++;
                if (element.actual_maasp) {
                  data.datasets.push({
                    label: element.annulus + " MAASP",
                    data: [element.actual_maasp, element.actual_maasp],
                    fill: false,
                    pointRadius: 0,
                    backgroundColor: c,
                    borderColor: c,
                    borderWidth: 5,
                  });
                }
              });
          }
          setTimeout(() => {
            var ctx = document.getElementById(this.chart_id);
            new Chart(ctx, config);
          }, 50);
        }
      }
    },
    sortBy() {
      return function (a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      };
    },
    dialog_close() {
      this.$emit("close");
    },
  },
  watch: {},
  mounted() {
    this.$emit("chart-ready", document.querySelector("#chart_element"));
  },
};
</script>
